<template>
  <v-container id="login" class="fill-height justify-center mt-16" tag="section">
    <div class="d-flex flex-wrap justify-center mb-2 mt-6" style="width: 100%">
      <img src="@/assets/logo/Logo-Verde.png" height="50px" />
    </div>

    <div class="d-flex flex-wrap justify-center mb-12" style="width: 100%; font-size: 20px; font-weight: 300;" >
      <h4 style="font-weight: 400;">
        {{ $t("Genetica") }} · {{ $t("FarmacoGenetica") }}
      </h4>
    </div>

    <!-- <detalle-animado color="5DFFBD"></detalle-animado> -->

    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card color="success" light max-width="100%" width="450" class="px-5 py-3" >
          <div class="text-center">
            <h1 class="display-2 font-weight-bold mb-8" style="color: #1C093A;">
              {{ $t("Iniciar sesion") }}
            </h1>
          </div>
          <!-- <template v-slot:heading>
          </template> -->
          <validation-observer v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(login)">
              <v-card-text class="text-center pa-0">
                <validation-provider v-slot="{ errors }" rules="required" name="email" >
                  <v-text-field autocomplete="off" rounded outlined v-model="crendentials.email" :label="$t('Direccion email') + '*'" :error-messages="errors" color="secondary" />
                </validation-provider>
                <validation-provider v-slot="{ errors }" rules="required" name="password" >
                  <v-text-field autocomplete="off" rounded outlined v-model="crendentials.password" :label="$t('Contrasena') + '*'" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'" :error-messages="errors" color="primary" @click:append.prevent="showPassword = !showPassword" />
                </validation-provider>
              </v-card-text>
              <v-card-actions class="pa-0 justify-center">
                <v-btn default rounded color="primary" type="submit" class="font-weight-bold accent--text mr-0 ml-2" >
                  {{ $t("Identificarse") }}
                </v-btn> </v-card-actions>
          
              <v-card-actions class="pa-0 justify-center">
                {{$t("todavia_no_tienes_cuenta")}}<a class="ml-1" @click="$router.push({name: 'Register'})"> {{$t("registrate")}}</a>
              </v-card-actions>
              <v-card-actions class="pa-0 justify-center">
                {{$t("has_olvidado_tu_contrasena")}}<a class="ml-1" @click="$router.push({name: 'Recover'})"> {{$t("recuperar_contrasena")}}</a>
              </v-card-actions>
              <v-card-actions class="pa-0 justify-center">
              </v-card-actions>
            </form>
          </validation-observer>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  components: {},
  name: "PagesLogin",
  $_veeValidate: {
    validator: "new"
  },

  data: () => ({
    showPassword: false,
    crendentials: {
      password: "",
      email: ""
    },
  }),
  mounted() {
    if (this.$route.query.email) {
      this.crendentials.email = this.$route.query.email
    }
  },
  methods: {
    ...mapActions("auth", ["attemptLogin"]),
    login() {
      let self = this;
      self.$nprogress.start();
      this.attemptLogin({ ...this.crendentials })
        .then(() => {
          this.$router.push(this.$route.query.redirect || { name: "Home" });
        })
        .catch(error => {
          self.$dialog.error({
            text: self.$t(error.message),
            title: self.$t(`Algo salio mal al iniciar la sesion`),
            icon: false,
            actions: [self.$t("Cerrar")]
          });
        })
        .then(() => {
          self.$nprogress.done();
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.v-dialog.v-card {
  background: #ff00ff !important;
}
</style>